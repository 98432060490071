import React, { useEffect, useState } from "react";
import {
  FetchDashboardData,
  FetchDashboardStreamData,
  FetchUpcomingEvents,
} from "../Api/ApiEndPoints";
import {
  AddFlex,
  CustomText,
  Gap,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import ScheduledCalls from "../Pages/ScheduledCalls";
import ActivityStream from "../Pages/ActivityStream";
import { callNonStoreApi } from "../NonStoreApis";
import Loading from "../Loading";
import { enqueueSnackbar } from "notistack";
import AnalyticsView from "../Pages/AnalyticsView";
import useWindowSize from "../Hooks/useWindowSize";
import UpcomingEvents from "./UpcomingEvents";
import QuickActions from "./QuickActions";
import BannerSection from "./BannerSection";
import { useSelector } from "react-redux";
import { Status, isArrayNullOrUndefined, isNullOrUndefined } from "../utils";
import { useSearchParams } from "react-router-dom";

function DashboardTab() {
  const [basicApiData, setBasicApiData] = useState(null);
  const [streamData, setStreamData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { windowSize, isMobileView } = useWindowSize();
  const [isStreamDataLoading, setIsStreamDataLoading] = useState(true);
  const [isSetupView, setIsSetupView] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { features } = useSelector((state) => state.userFeatures.userFeatures);

  const handleGetIsOneOfferingInPublished = () => {
    if (isNullOrUndefined(features)) return false;
    const _features = Object.values(features);
    let isOneOfferingPublished = false;
    for (let feature of _features) {
      if (isArrayNullOrUndefined(feature.items)) {
        isOneOfferingPublished = true;
        break;
      }
      const isFeatureHasPublishedOffering = feature.items.some(
        (item) => item.status === Status.PUBLISHED
      );
      if (isFeatureHasPublishedOffering) {
        isOneOfferingPublished = true;
        break;
      }
    }
    return isOneOfferingPublished;
  };

  const handleGetOfferingDashboardData = async () => {
    try {
      const response = await callNonStoreApi(FetchDashboardData, {});
      delete response.data.calls._id;
      setBasicApiData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetStreamData = async () => {
    try {
      const response = await callNonStoreApi(FetchDashboardStreamData, {});
      setStreamData(response.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsStreamDataLoading(false);
    }
  };

  useEffect(() => {
    handleGetOfferingDashboardData();
    handleGetStreamData();
    setIsSetupView(!handleGetIsOneOfferingInPublished());
  }, []);

  useEffect(() => {
    setIsSetupView(!handleGetIsOneOfferingInPublished());
    setSearchParams({});
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      //TODO: API CALL FOR GETTING STREAM DATA
      handleGetStreamData();
    }, 300000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return isLoading ? (
    <AddFlex
      alignItems="center"
      justify="center"
      style={{ width: "100%", height: "100%" }}
    >
      <Loading />
    </AddFlex>
  ) : (
    <>
      {isSetupView ? (
        <AddFlex
          marginTop={!isMobileView() && "48px"}
          padding={isMobileView() ? "20px" : "0 48px"}
          flexDirection="column"
          gap="24px"
        >
          {!isMobileView() && <BannerSection />}
          <QuickActions />
        </AddFlex>
      ) : (
        <AddFlex
          padding={isMobileView() ? "20px" : "16px 48px"}
          height="100%"
          style={{
            position: "relative",
            // overflow: isMobileView() ? "scroll" : "hidden",
          }}
          transition="none"
          wrap="wrap"
        >
          <AddFlex
            flexDirection="column"
            gap="33px"
            padding="5px"
            width={isMobileView() ? "100%" : "60%"}
          >
            <AnalyticsView />
            <UpcomingEvents />
            {/* <ScheduledCalls calls={basicApiData?.calls} /> */}
          </AddFlex>
          <Gap />
          {!isStreamDataLoading && <ActivityStream streamData={streamData} />}
        </AddFlex>
      )}
      <SecondaryActionButton
        style={{
          position: "absolute",
          right: isMobileView() ? "20px" : "48px",
          top: isMobileView() ? "15px" : "30px",
          borderRadius: "25px",
          cursor: "pointer",
          padding: "8px 10px",
          width: "max-content",
        }}
        onClick={() => {
          if (!isSetupView) {
            setSearchParams({ tabName: "Quick Setup" });
          } else {
            setSearchParams({});
          }
          setIsSetupView((prev) => !prev);
        }}
      >
        {isSetupView ? "Back to home" : "Quick Setup"}
      </SecondaryActionButton>
    </>
  );
}

export default DashboardTab;
