import React, { useState } from "react";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import { isNullOrUndefined } from "../utils";
import SidebarNavLink from "./SidebarNavLink";
import { DASHBOARD_ROUTE, INVOICE_ROUTE } from "../OnboardingPage/routeNames";
import useFeaturesFlagsHook from "../CustomHooks.js/useFeaturesFlagsHook";
import { ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";

function SidebarSection({
  item,
  selectedTabRoute,
  isDesktopView,
  invertSideBarVisibility,
  setSelectedTabRoute,
}) {
  const { isFeatureEnabled } = useFeaturesFlagsHook();
  const [isExpanded, setIsExpanded] = useState(true);
  const { userName } = useSelector((state) => state.user.apiUserData);

  const isSelected = (item) => {
    if (
      selectedTabRoute === DASHBOARD_ROUTE &&
      item.id === "sidebar_main_dashboard"
    ) {
      return true;
    }
    return (
      selectedTabRoute.includes(item.route) &&
      item.id !== "sidebar_main_dashboard"
    );
  };
  return (
    <AddFlex
      flexDirection="column"
      transition="none"
      marginBottom={isExpanded ? "16px" : "0px"}
    >
      {item.name && (
        <AddFlex
          onClick={() => setIsExpanded((prev) => !prev)}
          margin="0 0 12px 14px"
          alignItems="center"
          style={{ cursor: "pointer" }}
        >
          <CustomText color="black" fontWeight="600" fontSize="12px">
            {item.name.toUpperCase()}
          </CustomText>
          <Gap />
          <ExpandMore
            sx={{
              transform: isExpanded && "rotate(-180deg)",
            }}
          />
        </AddFlex>
      )}
      {isExpanded && (
        <AddFlex flexDirection="column">
          {item.items
            .filter(
              (item) =>
                item.route !== INVOICE_ROUTE ||
                userName === "karehealth" ||
                userName === "company"
            )
            .map(
              (navItem) =>
                (isNullOrUndefined(navItem.isPaid) ||
                  isFeatureEnabled("sidebar.tabs", navItem.id)) && (
                  <SidebarNavLink
                    key={navItem.id}
                    isSelected={isSelected(navItem)}
                    setSelectedTabRoute={setSelectedTabRoute}
                    item={navItem}
                    onClick={() => {
                      console.log(isDesktopView(), "called");
                      if (!isDesktopView()) {
                        invertSideBarVisibility();
                      }
                    }}
                  />
                )
            )}
        </AddFlex>
      )}
    </AddFlex>
  );
}

export default SidebarSection;
