import { useNavigate } from "react-router";
import {
  FeaturesKeys,
  dashboardFeatureIdentifierBackgroundColor,
  dashboardFeatureIdentifierTextColor,
  getFeatureNameFromId,
  isNullOrUndefined,
  rupee,
} from "./utils";
import { primaryActionColor } from "./constants";
import { AddFlex, CustomText } from "./reusableStyles";
import { IOSSwitch } from "./CustomSwitch";
import { DeleteOutline } from "@mui/icons-material";
import { format } from "date-fns";
import { EDIT_COUPON } from "./OnboardingPage/routeNames";
import useWindowSize from "./Hooks/useWindowSize";

const getUsageLimit = (limit) => {
  switch (limit) {
    case 1:
      return "Only Once";
    case 2:
      return "Only Twice";
    case 3:
      return "Only three times";
    default:
      return "No limit";
  }
};

const getCouponEligibility = (eligibility, user) => {
  if (eligibility.allCustomers) {
    return "All Customers";
  }
  if (eligibility.myCustomers) {
    return "Only " + user + " customers";
  }
  return "Custom";
};

const getCouponOfferString = (couponType, values) => {
  if (isNullOrUndefined(values)) return "";
  if (couponType === "flat") {
    if (
      !isNullOrUndefined(values.minimumBillValue) &&
      Number(values.minimumBillValue) > 0
    ) {
      return `FLAT ${rupee()}${
        values.value
      } OFF on orders worth more than ${rupee()}${values.minimumBillValue}`;
    } else {
      return `FLAT ${rupee()}${values.value} OFF`;
    }
  } else {
    if (
      !isNullOrUndefined(values.minimumBillValue) &&
      Number(values.minimumBillValue) > 0
    ) {
      return values.maximumDiscount
        ? `${values.value}% OFF upto ${rupee()} ${
            values.maximumDiscount
          } on orders worth more than ${rupee()}${values.minimumBillValue}`
        : `${values.value}% OFF`;
    } else {
      return values.maximumDiscount
        ? `${values.value}% OFF upto ${rupee()} ${values.maximumDiscount}`
        : `${values.value}% OFF`;
    }
  }
};

const EventCouponCodeCard = ({
  coupon,
  user,
  isEvent,
  handleEnableDisableCoupon,
  handleDeleteCoupon,
}) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const handleChangeIsCouponEnabled = async (e) => {
    e.stopPropagation();
    const enabledStatus = !isNullOrUndefined(coupon.isCouponActive)
      ? !coupon.isCouponActive
      : false;

    await handleEnableDisableCoupon({
      isEdit: true,
      sellerId: user.userId,
      couponObj: { ...coupon, isCouponActive: enabledStatus },
      isCouponNameChanged: false,
    });
  };
  const handleDelete = async () => {
    await handleDeleteCoupon({
      sellerId: user.userId,
      couponId: coupon.couponId,
      featureId: coupon.featureId,
    });
  };
  const navigateToEditCoupon = () => {
    navigate(
      EDIT_COUPON.replace(":couponId", coupon.couponId).replace(
        ":featureId",
        coupon.featureId
      ),
      {
        state: {
          couponData: coupon,
        },
      }
    );
  };

  const couponCreatedForOfferingType = () => {
    switch (coupon.pageTypeId) {
      case FeaturesKeys.event.id:
        return FeaturesKeys.event.name;
      case FeaturesKeys.course.id:
        return FeaturesKeys.course.name;
      case FeaturesKeys.webinar.id:
        return FeaturesKeys.webinar.name;
      case FeaturesKeys.travel.id:
        return FeaturesKeys.travel.name;
      case FeaturesKeys.subscription.id:
        return FeaturesKeys.subscription.name;
      default:
        return "";
    }
  };

  const getAppliedToComponent = () => {
    return coupon.offerings?.isAllSelected ? (
      <CustomText margin="0 0 0 5px" color={"black"}>
        ALL OFFERINGS
      </CustomText>
    ) : (
      <CustomText margin="0 0 0 5px" color={"black"}>
        {coupon.offerings?.selectedOfferings.length > 1
          ? `${coupon.offerings?.selectedOfferings.length} Offerings`
          : `${coupon.offerings?.selectedOfferings.length} Offering`}
      </CustomText>
    );
  };

  const handleCheckIfCouponHasOverseasCriteria = () => {
    if (coupon.couponCodeType === "percentage") {
      return (
        Object.keys(coupon.percentageValue).length > 1 ||
        !Object.keys(coupon.percentageValue).includes("INR")
      );
    }
    return (
      Object.keys(coupon.flatValue).length > 1 ||
      !Object.keys(coupon.percentageValue).includes("INR")
    );
  };

  const handleGetCouponCurrencies = () => {
    if (coupon.couponCodeType === "percentage") {
      if (isNullOrUndefined(coupon.percentageValue)) return [];
      return Object.keys(coupon.percentageValue);
    }
    if (isNullOrUndefined(coupon.flatValue)) return [];
    return Object.keys(coupon.flatValue);
  };

  return (
    <AddFlex
      flexDirection="column"
      style={{
        cursor: "pointer",
        width: isMobileView() ? "100%" : "23vw",
        margin: "15px 15px 0 0",
        boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.05)",
      }}
      position="relative"
      backgroundColor="white"
      borderRadius="10px"
      border={"1px dashed " + primaryActionColor}
      onClick={navigateToEditCoupon}
    >
      <AddFlex
        flexDirection="column"
        backgroundColor={coupon.isCouponActive ? primaryActionColor : "grey"}
        padding="8px 10px"
        borderRadius="10px 10px 0 0"
      >
        <CustomText
          fontSize="18px"
          color={"white"}
          fontFamily="Poppins"
          fontWeight="600"
        >
          {coupon.couponCodeName}
        </CustomText>
        {handleCheckIfCouponHasOverseasCriteria() ? (
          <AddFlex flexDirection="column">
            <CustomText fontSize="12px" color={"white"} fontWeight="bold">
              THIS COUPON HAS OVERSEAS CRITERIA
            </CustomText>
          </AddFlex>
        ) : (
          <>
            <CustomText fontSize="12px" color={"white"} fontWeight="bold">
              {getCouponOfferString(
                coupon.couponCodeType,
                coupon.couponCodeType === "percentage"
                  ? coupon.percentageValue[handleGetCouponCurrencies()[0]]
                  : coupon.flatValue[handleGetCouponCurrencies()[0]]
              )}
            </CustomText>
          </>
        )}
      </AddFlex>
      <AddFlex flexDirection="column" padding="10px">
        <AddFlex
          padding="5px 15px"
          width="max-content"
          borderRadius="25px"
          style={{ fontSize: "12px" }}
          position="absolute"
          right="10px"
          backgroundColor={
            dashboardFeatureIdentifierBackgroundColor[coupon.pageTypeId]
          }
          color={dashboardFeatureIdentifierTextColor[coupon.pageTypeId]}
        >
          {couponCreatedForOfferingType()}
        </AddFlex>
        <CouponCardTextComponent
          title={"Valid Till:"}
          description={format(new Date(coupon.couponValidTill), "dd MMM yyyy")}
        />
        <CouponCardTextComponent
          title={"Usage Limit:"}
          description={getUsageLimit(coupon.usageLimit)}
        />
        <CouponCardTextComponent
          title={"Applied To:"}
          description={getAppliedToComponent()}
        />
        <CouponCardTextComponent
          title={"Eligibility:"}
          description={getCouponEligibility(
            coupon.couponAccessibility,
            user.Name
          )}
        />
      </AddFlex>
      <AddFlex
        style={{ position: "absolute", top: "8px", right: "5px" }}
        borderRadius="2px"
        padding="4px"
        onClick={(e) => e.stopPropagation()}
      >
        <CustomText color={"white"} fontSize="12px" fontWeight="600">
          {coupon.isCouponActive ? "LIVE" : "DISABLED"}
        </CustomText>
      </AddFlex>
      <AddFlex
        alignItems="center"
        gap="8px"
        style={{ position: "absolute", bottom: "10px", right: "10px" }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <FontAwesomeIcon icon={faGear} /> */}
        <IOSSwitch
          checked={coupon.isCouponActive}
          onChange={handleChangeIsCouponEnabled}
        />
        <DeleteOutline
          onClick={handleDelete}
          style={{ color: coupon.isCouponActive ? primaryActionColor : "grey" }}
        />
      </AddFlex>
    </AddFlex>
  );
};

export default EventCouponCodeCard;

const CouponCardTextComponent = ({ title, description }) => {
  return (
    <AddFlex margin="5px 0 0 0">
      <CustomText color={"black"} fontWeight="400">
        {title}
      </CustomText>
      <CustomText margin="0 0 0 5px" color={"black"} fontWeight="300">
        {description}
      </CustomText>
    </AddFlex>
  );
};
