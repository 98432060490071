import { Backdrop, Dialog, Drawer } from "@mui/material";
import React from "react";
import GalleryComponent from "./CreateOffering/GalleryComponent";
import useWindowSize from "./Hooks/useWindowSize";

function GalleryComponentWrapper({
  openGallery,
  isMultiple,
  files,
  handleGetFiles,
  setOpenGallery,
}) {
  const { isMobileView } = useWindowSize();
  return isMobileView() ? (
    <Drawer
      open={openGallery}
      onClick={() => {
        setOpenGallery(false);
      }}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderRadius: "24px 24px 0 0 ",
        },
      }}
    >
      <GalleryComponent
        isMultiple={isMultiple}
        open={openGallery}
        prevSelectedImages={files}
        closeBackdrop={() => setOpenGallery(false)}
        handleGetSelectedImages={handleGetFiles}
      />
    </Drawer>
  ) : (
    <Dialog
      open={openGallery}
      onClick={() => {
        setOpenGallery(false);
      }}
      PaperProps={{
        sx: {
          maxWidth: "unset",
        },
      }}
    >
      <GalleryComponent
        isMultiple={isMultiple}
        open={openGallery}
        prevSelectedImages={files}
        closeBackdrop={() => setOpenGallery(false)}
        handleGetSelectedImages={handleGetFiles}
      />
    </Dialog>
  );
}

export default GalleryComponentWrapper;
