import { useSelector } from "react-redux";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  FILE_TYPES,
  generateUUID,
  handleImageUpload,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import { useEffect, useState } from "react";
import {
  ActionButton,
  AddFlex,
  CustomText,
  DialogHeader,
  PrimaryActionButton,
} from "../reusableStyles";
import { CloseRounded, FileUpload } from "@mui/icons-material";
import {
  inputHeight,
  primaryActionColor,
  primaryTextColor,
} from "../constants";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import GalleryViewBody from "./GalleryViewBody";
import { AddImagesToGallery } from "../Api/ApiEndPoints";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { useDispatch } from "react-redux";
import imageCompression from "browser-image-compression";
import useWindowSize from "../Hooks/useWindowSize";

const GalleryViewHeader = ({ closeBackdrop }) => {
  return (
    <AddFlex
      width="100%"
      height="10%"
      justify="space-between"
      padding="15px 30px"
      style={{ borderBottom: "1px solid #ececec" }}
    >
      <CustomText fontSize="16px" fontWeight="500" color="black">
        My Files
      </CustomText>
      <CloseRounded onClick={closeBackdrop} />
    </AddFlex>
  );
};

function GalleryComponent({
  prevSelectedImages,
  isMultiple,
  closeBackdrop,
  showOnly,
  open,
  handleGetSelectedImages,
  isProfileImage,
}) {
  const { otherData } = useSelector((state) => state.userFeatures.userFeatures);
  const { isMobileView } = useWindowSize();
  const getUserImageGallery = () => {
    let requestedType;
    if (showOnly) {
      requestedType = showOnly;
    }
    if (isNullOrUndefined(otherData?.gallery)) {
      return null;
    }
    console.log(otherData?.gallery, requestedType);
    return requestedType
      ? otherData?.gallery?.filter((file) => {
          if (requestedType === "image" && isNullOrUndefined(file.type)) {
            return true;
          }
          return file.type?.includes(requestedType);
        })
      : otherData?.gallery;
  };
  const [imagesArray, setImagesArray] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleSetInitial = () => {
    if (isNullOrUndefined(getUserImageGallery())) {
      setImagesArray([]);
      return;
    } else {
      if (isArrayNullOrUndefined(prevSelectedImages)) {
        setSelectedImages([]);
      } else {
        setSelectedImages(prevSelectedImages);
      }
      setImagesArray([...getUserImageGallery()]);
    }
  };

  const handleSelectDeselectImages = (_image, isMultiple) => {
    if (!isMultiple) {
      setSelectedImages([_image]);
      return;
    }
    if (isArrayNullOrUndefined(selectedImages)) {
      setSelectedImages([_image]);
      return;
    }
    if (selectedImages.some((image) => image.id === _image.id)) {
      setSelectedImages(
        selectedImages.filter((image) => image.id !== _image.id)
      );
    } else {
      setSelectedImages([...selectedImages, _image]);
    }
  };

  useEffect(() => {
    handleSetInitial();
    const scrollableElement = document.getElementById("galleryComponentBody");
    if (isNullOrUndefined(scrollableElement)) return;
    scrollableElement.scrollTop = scrollableElement?.scrollHeight;
  }, [prevSelectedImages, showOnly, open]);

  return (
    imagesArray && (
      <AddFlex
        flexDirection="column"
        width={isMobileView() ? "100vw" : "95vw"}
        style={{ height: "90vh" }}
        // height="70vh"
        borderRadius="8px"
        backgroundColor="white"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <GalleryViewHeader
          isMultiple={isMultiple}
          closeBackdrop={closeBackdrop}
        /> */}
        <DialogHeader
          mainText={"Gallery"}
          subText={
            isMultiple
              ? "You can select multiple files"
              : "You can select only ONE file"
          }
          padding={"20px"}
          lineHeight={"unset"}
          subTextColor={"red"}
          subTextWeight="400"
          onClose={closeBackdrop}
        />
        <div
          id="galleryComponentBody"
          style={{ height: "80%", overflowY: "auto", overflowX: "hidden" }}
        >
          <GalleryViewBody
            images={imagesArray}
            selectedImages={selectedImages}
            isMultiple={isMultiple}
            setImagesArray={setImagesArray}
            handleSelectDeselectImages={handleSelectDeselectImages}
          />
        </div>
        <GalleryViewFooter
          imagesArray={imagesArray}
          setImagesArray={setImagesArray}
          selectedImages={selectedImages}
          isMultiple={isMultiple}
          showOnly={showOnly}
          closeBackdrop={closeBackdrop}
          isProfileImage={isProfileImage}
          handleGetSelectedImages={handleGetSelectedImages}
        />
      </AddFlex>
    )
  );
}

export default GalleryComponent;

const GalleryViewFooter = ({
  closeBackdrop,
  isMultiple,
  showOnly,
  selectedImages,
  handleGetSelectedImages,
  imagesArray,
  setImagesArray,
  isProfileImage,
}) => {
  return (
    <AddFlex
      alignItems="center"
      justify="space-between"
      padding="15px 30px"
      height="12%"
      style={{ borderTop: "1px solid #ececec" }}
    >
      <AddFlex display="flex" alignItems="center" justifyContent="center">
        <AddNewImageButton
          imagesArray={imagesArray}
          showOnly={showOnly}
          setImagesArray={setImagesArray}
          isProfileImage={isProfileImage}
        />
      </AddFlex>
      <PrimaryActionButton
        border={`1px solid ${primaryActionColor}`}
        borderRadius="4px"
        style={{
          height: inputHeight,
          padding: "0 20px",
        }}
        margin="0"
        type="button"
        onClick={() => {
          handleGetSelectedImages(isMultiple, selectedImages);
          closeBackdrop();
        }}
      >
        Done
      </PrimaryActionButton>
    </AddFlex>
  );
};

const AddNewImageButton = ({
  imagesArray,
  showOnly,
  setImagesArray,
  isProfileImage,
}) => {
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { otherData } = useSelector((state) => state.userFeatures.userFeatures);
  //   const { setUserFeatureData } = useUser();
  //   const { addImagesToGallery } = useFeatureServices();
  const { enqueueSnackbar } = useSnackbar();
  const { getInputProps, getRootProps } = useDropzone({
    accept: getAcceptableFiles(),
    maxSize: 1048576 * 10, //limit to 10MB
    multiple: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      handleGetFiles(acceptedFiles, rejectedFiles);
    },
  });

  function getAcceptableFiles() {
    switch (showOnly) {
      case FILE_TYPES.IMAGES: {
        return {
          "image/jpeg": [".jpeg", ".jpg", ".png", ".svg"],
        };
      }
      case FILE_TYPES.VIDEOS: {
        return {
          "video/mp4": [".mp4"],
        };
      }
      case FILE_TYPES.DOCS: {
        return {
          "application/pdf": [".pdf"],
        };
      }
      default: {
        return {
          "image/jpeg": [".jpeg", ".jpg", ".png", ".svg"],
          "application/pdf": [".pdf"],
          "video/mp4": [".mp4"],
        };
      }
    }
  }

  async function handleImageCompression(file) {
    const options = {
      maxSize: 10,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      console.log(compressedFile);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetFiles = async (acceptedFiles, rejectedFiles) => {
    try {
      if (loading) return;
      const imagesArray = otherData?.gallery || [];
      setIsLoading(true);
      if (rejectedFiles.length > 0) {
        enqueueSnackbar("Max File size allowed 10MB", {
          variant: "error",
          hideIconVariant: true,
        });
        throw new Error("Max File size allowed 10MB");
      }
      const imgUrls = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        console.log(acceptedFiles[i].type);
        imgUrls.push({
          id: generateUUID(),
          uploaded: true,
          name: acceptedFiles[i].name,
          type: acceptedFiles[i].type,
          isVideo: acceptedFiles[i].type === "video/mp4",
          src: await handleImageUpload(
            !acceptedFiles[i].type.includes("image")
              ? acceptedFiles[i]
              : await handleImageCompression(acceptedFiles[i]),
            "gallery_view",
            isProfileImage
          ),
        });
      }
      //upload URLs to DB.
      const newImages = [...imagesArray, ...imgUrls];
      dispatch(
        userFeatureApi({
          payload: {
            newImages: newImages,
          },
          endpoint: AddImagesToGallery,
        })
      ).then((actionResult) => {
        if (showOnly) {
          setImagesArray([
            ...imagesArray.filter((file) => {
              if (showOnly === "image" && isNullOrUndefined(file.type)) {
                return true;
              }
              return file.type?.includes(showOnly);
            }),
            ...imgUrls,
          ]);
        } else {
          setImagesArray([...imagesArray, ...imgUrls]);
        }
        if (actionResult.meta.requestStatus === "fulfilled") {
          // Handle success
          enqueueSnackbar("Your Images Has been Saved.", {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AddFlex
      flexDirection="column"
      // width="150px"
      // height="150px"
      padding="0px 12px"
      alignItems="center"
      justify="center"
      backgroundColor="white"
      borderRadius="4px"
      border={`1px solid ${primaryActionColor}`}
      {...getRootProps({ className: "dropzone" })}
    >
      <input {...getInputProps()} />
      <AddFlex alignItems="center" height={inputHeight}>
        {loading ? (
          <CircularProgress
            size={16}
            color="info"
            style={{ color: primaryActionColor }}
          />
        ) : (
          <FileUpload sx={{ color: primaryActionColor }} />
        )}
        <CustomText margin="0px 10px" color={primaryTextColor}>
          Upload
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};
