import React, { useState } from "react";
import {
  AddFlex,
  InputLabel,
  PrimaryActionButton,
} from "../../../reusableStyles";
import useWindowSize from "../../../Hooks/useWindowSize";
import { CircularProgress, Divider } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import {
  generateUUID,
  getCurrentTime,
  getToday,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../../utils";
import { format } from "date-fns";
import TimeSlotComponent from "../TimeSlotComponent";
import ScheduleRepeatingBlockHeader from "./ScheduleRepeatingBlockHeader";
import { TertiaryButton } from "./CoursesSchedule";
import StopBookingsCheckbox from "../StopBookingsCheckbox";
import FromToDates from "./FromToDates";
import ButtonWithAlertDialog from "../ButtonWithAlertDialog";
import { callNonStoreApi } from "../../../NonStoreApis";
import {
  CheckIsMeetingCreatedForSlot,
  CheckOverlappingTimeSlots,
  DeleteZoomMeeting,
  UpdateTimeSlotsInZoom,
} from "../../../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import moment from "moment-timezone";

function EventOrWorkshopScheduleComponentExpanded({
  isOnline,
  shouldUpdateZoom,
  dateString,
  index,
  handleAddNewDate,
  handleSetOffering,
  scheduleDetail,
  setIsExpanded,
  duration,
  timezone,
}) {
  const { isMobileView } = useWindowSize();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [isTimeSlotDeleting, setIsTimeSlotDeleting] = useState(false);
  const [newSchedule, setNewSchedule] = useState(
    scheduleDetail
      ? JSON.parse(JSON.stringify(scheduleDetail))
      : handleGetDefaultDetail()
  );

  function getDefaultScheduleDetails() {
    return {
      dateId: generateUUID(),
      date: getToday(),
      timeSlots: [
        {
          id: generateUUID(),
          time: getCurrentTime(),
          tickets: [],
          totalSeats: -1,
        },
      ],
    };
  }

  function handleGetDefaultDetail() {
    const newSchedule = getDefaultScheduleDetails();
    newSchedule.date = moment.tz(timezone).startOf("day").utc().format();
    return newSchedule;
  }

  const handleUpdateSchedule = (key, value) => {
    setNewSchedule((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAddSession = () => {
    var newTimeSlot = {
      id: generateUUID(),
      time: getCurrentTime(),
      tickets: [],
    };
    setNewSchedule((prev) => ({
      ...prev,
      timeSlots: [...prev.timeSlots, newTimeSlot],
    }));
  };
  const handleUpdateSessionTime = (timeSlotIndex, key, value) => {
    return setNewSchedule((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.map((timeSlot, index) => {
        if (index === timeSlotIndex) {
          return {
            ...timeSlot,
            [key]: value,
          };
        } else {
          return timeSlot;
        }
      }),
    }));
  };

  const handleDeleteTimeSlot = async (timeSlotIndex) => {
    const timeSlot = newSchedule.timeSlots[timeSlotIndex];
    const response = await callNonStoreApi(
      CheckIsMeetingCreatedForSlot.replace(":slotId", timeSlot.id)
    );

    const { isMeetingPresent, meeting } = response.data;
    if (isMeetingPresent) {
      const _response = await callNonStoreApi(
        DeleteZoomMeeting.replace(":meetingId", meeting.data.id)
      );
    }

    setNewSchedule((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.filter(
        (timeSlot, index) => index !== timeSlotIndex
      ),
    }));

    return;
  };

  const handleUpdateScheduleInParent = async () => {
    setLoading(true);
    if (isNullOrUndefined(index) || index === -1) {
      handleAddNewDate(newSchedule);
    } else {
      const isDateChanged = newSchedule.date !== scheduleDetail.date;
      if (shouldUpdateZoom) {
        for (
          let timeSlotIndex = 0;
          timeSlotIndex < newSchedule.timeSlots.length;
          timeSlotIndex++
        ) {
          const timeSlot = newSchedule.timeSlots[timeSlotIndex];
          // api to check if there exists a zoom meeting for this slot
          const response = await callNonStoreApi(
            CheckIsMeetingCreatedForSlot.replace(":slotId", timeSlot.id)
          );
          const { isMeetingPresent, meeting } = response.data;

          if (
            isMeetingPresent &&
            (timeSlot.time !== scheduleDetail.timeSlots[timeSlotIndex].time ||
              isDateChanged)
          ) {
            try {
              const response = await callNonStoreApi(UpdateTimeSlotsInZoom, {
                meeting_id: meeting.data.id,
                startDate: newSchedule.date,
                startTime: timeSlot.time,
              });
            } catch (error) {
              console.error(error);
              enqueueSnackbar(
                "Meeting update in zoom failed, please contact support.",
                {
                  variant: "error",
                }
              );
            }
          }
        }
      }
      handleSetOffering(`scheduleDetails.${index}`, newSchedule);
    }
    setIsExpanded(false);
    setLoading(false);
  };

  const handleCheckOverlapTimeslots = async () => {
    newSchedule.duration = duration;
    if (!isOnline) {
      handleUpdateScheduleInParent();
      setIsExpanded(false);
      return false;
    }
    const response = await callNonStoreApi(CheckOverlappingTimeSlots, {
      schedule: newSchedule,
    });
    if (response.data.hasOverlap) {
      setDescription(response.data.error.join(" "));
      return true;
    }

    handleUpdateScheduleInParent();
    return false;
  };

  return (
    <AddFlex
      flexDirection="column"
      style={{
        border: "1px solid #ececec",
        boxShadow: "0 0 1px 0 rgba(0,0,0,0.2)",
      }}
      height="80vh"
      backgroundColor="white"
      // borderRadius="12px"
    >
      <ScheduleRepeatingBlockHeader
        index={index}
        datesString={dateString}
        date={newSchedule.date}
        endDate={newSchedule.endDate}
        setIsExpanded={setIsExpanded}
        handleSetOffering={handleSetOffering}
      />
      <Divider />
      <AddFlex
        padding="30px"
        style={{ overflow: "scroll", paddingBottom: "80px", height: "100%" }}
        flexDirection="column"
      >
        <AddFlex flexDirection={isMobileView() && "column"} alignItems="center">
          <FromToDates
            handleUpdateSchedule={handleUpdateSchedule}
            scheduleDetail={newSchedule}
            showEndDate={false}
            timezone={timezone}
          />
        </AddFlex>

        <InputLabel color="black">
          Add Timeslots for ({format(new Date(newSchedule.date), "dd MMM yyyy")}
          )
        </InputLabel>
        {/**Time Selector */}
        <AddFlex
          margin="0 0 15px 0"
          wrap="wrap"
          style={{ width: isMobileView() ? "100%" : "50%" }}
        >
          {isArrayNullOrUndefined(newSchedule.timeSlots) ? (
            <TertiaryButton
              buttonName={"Add Timeslot"}
              onClick={handleAddSession}
            />
          ) : (
            newSchedule.timeSlots.map((slot, timeSlotIndex) => (
              <TimeSlotComponent
                key={slot.id}
                handleAddSession={handleAddSession}
                handleSetOffering={handleSetOffering}
                handleDeleteTimeSlot={handleDeleteTimeSlot}
                handleUpdateSessionTime={handleUpdateSessionTime}
                slot={slot}
                showDeleteIcon={newSchedule.timeSlots.length > 1}
                timeSlotIndex={timeSlotIndex}
                scheduleDetail={newSchedule}
              />
            ))
          )}
        </AddFlex>
        {!isNullOrUndefined(index) && (
          <StopBookingsCheckbox
            stopBookings={newSchedule.stopBookings}
            handleUpdateSchedule={handleUpdateSchedule}
            index={index}
          />
        )}
      </AddFlex>
      <AddFlex
        style={{
          boxShadow: "0 0 4px 2px rgba(0,0,0,0.05)",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
        }}
        padding="10px"
        backgroundColor="white"
      >
        <ButtonWithAlertDialog
          component={
            <PrimaryActionButton
              onClick={() => {
                return handleCheckOverlapTimeslots();
              }}
              margin="0 auto"
              style={{
                width: "50%",
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} size={14} />
              ) : (
                "Save"
              )}
            </PrimaryActionButton>
          }
          buttons={[
            {
              title: "Proceed Anyway",
              action: () => {
                handleUpdateScheduleInParent();
              },
            },
          ]}
          title={"Are You Sure?"}
          description={description}
        />
      </AddFlex>
    </AddFlex>
  );
}

export default EventOrWorkshopScheduleComponentExpanded;
