import React from "react";
import { AddFlex, CustomInput, CustomText } from "../reusableStyles";
import { FeaturesKeys, getFrontendBaseURL, isNullOrUndefined } from "../utils";
import AdvancedTabToggleTab from "./AdvancedTabToggleTab";
import Convinience from "./Convinience";
import useWindowSize from "../Hooks/useWindowSize";
import PaymentGatewayCharges from "../AccountCenterTab/PaymentGatewayCharges";
import CommissionCharges from "../AccountCenterTab/CommissionCharges";
import { Divider } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SuccessChip from "../Integrations/SuccessChip";
import ShareMediaAfterBooking from "./ShareMediaAfterBooking";
import ColorCustomisations from "../Customization/ColorCustomisations";

function Advanced({ offering, handleSetOffering }) {
  const { isMobileView } = useWindowSize();
  const [searchParams] = useSearchParams();
  const { userName, UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const handleConveneincePayingFlags = (
    eventDetailsKey,
    index,
    eventDetailsValue
  ) => {
    const arr = [...offering[eventDetailsKey]];
    arr[index] = eventDetailsValue;
    handleSetOffering(eventDetailsKey, arr);
  };

  const handleGSTPayingFlags = (eventDetailsKey, index, eventDetailsValue) => {
    const arr = [...offering[eventDetailsKey]];
    arr[index] = eventDetailsValue;
    handleSetOffering(eventDetailsKey, arr);
  };
  return (
    <AddFlex
      gap="32px"
      flexDirection="column"
      width={isMobileView() ? "100%" : "80%"}
      style={{ overflow: "hidden" }}
    >
      <ColorCustomisations
        onSubmit={(selectedColors) => {
          handleSetOffering("themeColors", selectedColors);
        }}
      />

      {UserAdditionalDetails?.kycDetails?.taxDetails?.isGSTRegistered && (
        <AdvancedTabToggleTab
          checked={offering.isGSTExclusivePrice}
          tabName={"Add 18% GST to ticket price"}
          margin={"0px 0 0 0"}
          onChange={(e) => {
            handleSetOffering("isGSTExclusivePrice", e.target.checked);
          }}
        ></AdvancedTabToggleTab>
      )}
      {searchParams.get("pageType") === FeaturesKeys.course.name && (
        <AdvancedTabToggleTab
          checked={offering.takeBookingsAfterDatePasses}
          tabName={"Take bookings after the start date has crossed"}
          onChange={(e) => {
            handleSetOffering("takeBookingsAfterDatePasses", e.target.checked);
          }}
        ></AdvancedTabToggleTab>
      )}
      <AdvancedTabToggleTab
        checked={!isNullOrUndefined(offering.community)}
        tabName={"Community Links"}
        onChange={(e) => {
          if (!e.target.checked) {
            handleSetOffering("community", null);
          } else {
            handleSetOffering("community", {});
          }
        }}
      >
        <AddFlex
          flexDirection="column"
          margin={isMobileView() ? "20px 10px" : "24px 0 0 0"}
          // padding="0 10px"
        >
          <CustomInput
            label={"Community Name"}
            placeholder={"Enter your community name"}
            value={offering.community?.communityDescription}
            onChange={(text) =>
              handleSetOffering("community.communityDescription", text)
            }
          />
          <CustomInput
            label={"Community Link"}
            margin={"20px 0 0 0"}
            value={offering.community?.communityLink}
            placeholder={"Enter your community link"}
            onChange={(text) =>
              handleSetOffering("community.communityLink", text)
            }
          />
        </AddFlex>
      </AdvancedTabToggleTab>
      <CustomInput
        label={"Footer website link"}
        value={
          isNullOrUndefined(offering.footerWebsiteLink)
            ? getFrontendBaseURL(userName)
            : offering.footerWebsiteLink
        }
        placeholder={"Enter your custom website link"}
        onChange={(text) => handleSetOffering("footerWebsiteLink", text)}
      />
      <AddFlex flexDirection="column" gap="10px">
        <SuccessChip
          disableAction={true}
          successChipText={
            UserAdditionalDetails.paymentProvider === "growezy"
              ? "Growezy's Payment Gateway"
              : "Razorpay"
          }
          style={{ width: "max-content" }}
        />
        <PaymentGatewayCharges
          getWhoPaysConvenience={(index) => {
            return offering.isConvinienceIncluded[index] === false ? 1 : 0;
          }}
          handleChangeWhoPaysConvenience={(index, isConvenienceIncluded) => {
            handleSetOffering(
              `isConvinienceIncluded.${index}`,
              isConvenienceIncluded
            );
          }}
          style={{ padding: "0px", borderRadius: "0px" }}
        />
      </AddFlex>
      <Divider sx={{ margin: "0px 0 0 0" }} />
      <CommissionCharges
        getWhoPaysCommission={() => {
          return offering.commission?.isCommissionIncluded === false ? 1 : 0;
        }}
        handleChangeWhoPaysCommission={(isCommissionIncluded) => {
          handleSetOffering(
            `commission.isCommissionIncluded`,
            isCommissionIncluded
          );
        }}
        style={{ padding: "0px", borderRadius: "0px" }}
      />
      <Divider sx={{ margin: "0px 0 0 0" }} />
      <ShareMediaAfterBooking
        sharableMedia={offering.sharableMedia}
        handleSetOffering={handleSetOffering}
      />
    </AddFlex>
  );
}

export default Advanced;
