import React, { useEffect, useRef, useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import Tabs from "../Tabs";
import BasicDetails from "./Components/BasicDetails";
import useWindowSize from "../Hooks/useWindowSize";
import {
  FeaturesKeys,
  Status,
  getSavedStateData,
  isNullOrUndefined,
  isOfferingPublishable,
} from "../utils";
import Content from "./Content";
import Advanced from "./Advanced";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { produce } from "immer";
import {
  UpdateFeature,
  UpdateScheduleDetailsInCronJobs,
} from "../Api/ApiEndPoints";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Error } from "@mui/icons-material";
import customClientAxios from "../customClientAxios";
/*
    1. Tabs
        1. Basic Details.
        2. Content.
        3. Advanced.
    2. Offering Name.
    3. Venue Details.
    4. City.
*/

export const HeaderColor = "#344054";
export const SubHeaderColor = "#475467";

const tabs = [
  {
    id: "offering_tabs_1",
    name: "Basic Details",
  },
  {
    id: "offering_tabs_1",
    name: "Content",
  },
  {
    id: "offering_tabs_1",
    name: "Advanced",
  },
];

function CreateOfferingMain() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [savedChangesState, setSavedChangesState] = useState("saved");
  const [scheduleDetails, setScheduleDetails] = useState();
  const [timezone, setTimezone] = useState();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const { userFeatures } = useSelector((state) => state.userFeatures);
  // const { sortScheduleDetails } = useSelector((state) => state.appTemp.data);

  const { offeringId } = useParams();
  const [offering, setOffering] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [tabIcons, setTabIcons] = useState({});
  const [initial, setInitial] = useState(null);
  const offeringRef = useRef(offering);
  const initialRef = useRef(initial);
  const debounceTimer = useRef();
  const { isMobileView } = useWindowSize();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const offering = userFeatures.features[
      FeaturesKeys[getPageType()].id
    ].items.find((item) => item.id === offeringId);
    if (isNullOrUndefined(offering)) {
      //TODO: show not found screen
    } else {
      setInitial(offering);
      initialRef.current = offering;
      offeringRef.current = offering;
      setScheduleDetails(offering.scheduleDetails);
      setTimezone(offering.timezone);
      setOffering(offering);
      setLoading(false);
    }
    return () => {
      callDispatch();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasDataModified()) {
        const message =
          "There might be some unsaved changes,Are you sure you want to leave?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some browsers, notably Safari
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const debounce = (callback, delay) => {
    if (debounceTimer.current) {
      setSavedChangesState("unsaved");
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(callback, delay);
  };

  const hasDataModified = () => {
    return (
      JSON.stringify(initialRef.current) !== JSON.stringify(offeringRef.current)
    );
  };

  const getTabErrors = () => {
    if (
      errors["name"] ||
      errors["ctas"] ||
      errors["urlKey"] ||
      errors["pricing"]
    ) {
      setTabIcons((prev) => {
        return {
          ...prev,
          0: [<Error sx={{ color: "red", fontSize: "16px" }} />],
        };
      });
    }
    if (errors["description"] || errors["coverImage"] || errors["notes"]) {
      setTabIcons((prev) => {
        return {
          ...prev,
          1: [<Error sx={{ color: "red", fontSize: "16px" }} />],
        };
      });
    }
  };

  const apiCall = () => {
    //do api
    //TODO: show not found screen
    if (!isNullOrUndefined(offering) && hasDataModified()) {
      if (
        offering.status === Status.PUBLISHED &&
        !(isOfferingPublishable(offering, getPageType()).complete === 100)
      ) {
        setErrors(isOfferingPublishable(offering, getPageType()).errors);
        setSavedChangesState("error");
        return;
      }
      setSavedChangesState("saving");
      // if (
      //   JSON.stringify(scheduleDetails) !==
      //     JSON.stringify(offering.scheduleDetails) ||
      //   timezone !== offering.timezone
      // ) {
      //   customClientAxios()
      //     .post(UpdateScheduleDetailsInCronJobs, {
      //       scheduleDetails: offering.scheduleDetails,
      //       featureId: FeaturesKeys[getPageType()].id,
      //       featureItemId: offering.id,
      //       offeringName: offering.name,
      //       timezone: offering.timezone,
      //     })
      //     .then((data) => {
      //       console.log(data);
      //     });
      // }
      callDispatch().then((actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          setSavedChangesState("saved");
          setErrors({});
          setTabIcons({});
          setInitial(offering);
          setScheduleDetails(offering.scheduleDetails);
          setTimezone(offering.timezone);
          initialRef.current = offering;
        }
      });
    } else if (!hasDataModified()) {
      setSavedChangesState("saved");
      setErrors({});
      setTabIcons({});
    }
  };
  useEffect(() => {
    setTabIcons({});
    getTabErrors();
  }, [errors]);
  //auto save logic
  useEffect(() => {
    if (isNullOrUndefined(offering)) return;
    offeringRef.current = offering;
    debounce(apiCall, 1500);
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [offering]);

  const callDispatch = () => {
    if (
      offeringRef.current.status === Status.PUBLISHED &&
      !(
        isOfferingPublishable(offeringRef.current, getPageType()).complete ===
        100
      )
    ) {
      setShowAlertDialog(true);
      return;
    }
    return dispatch(
      userFeatureApi({
        payload: {
          action: "update",
          itemData: offeringRef.current,
          featureId: FeaturesKeys[getPageType()].id,
        },
        endpoint: UpdateFeature,
      })
    );
  };

  const getPageType = () => {
    return searchParams.get("pageType")?.toLowerCase();
  };

  function handleSetOffering(key, value) {
    setOffering(
      produce((prev) => {
        var paths = key.split(".");
        var currentPath = prev;
        for (let i = 0; i < paths.length; i++) {
          const isLast = i === paths.length - 1;
          if (paths[i] === "push" && Array.isArray(currentPath)) {
            if (isLast) {
              currentPath.push(value); // Add new item to the array
            }
          } else if (paths[i] === "delete") {
            const deleteIndex = parseInt(paths[i + 1], 10);
            if (
              !isNaN(deleteIndex) &&
              Array.isArray(currentPath) &&
              deleteIndex < currentPath.length
            ) {
              currentPath.splice(deleteIndex, 1); // Delete item at index
              break; // Exit after deletion to prevent further processing
            }
          } else if (!isLast) {
            // Navigate through the path
            const nextSegmentIsNumeric = !isNaN(parseInt(paths[i + 1], 10));
            if (!currentPath[paths[i]]) {
              // Initialize as an array if the next segment is numeric, otherwise as an object
              currentPath[paths[i]] = nextSegmentIsNumeric ? [] : {};
            }
            currentPath = currentPath[paths[i]];
          } else {
            // Update or set the value
            currentPath[paths[i]] = value;
          }
        }
      })
    );
  }

  const getTabData = (selectedTabIndex) => {
    switch (selectedTabIndex) {
      case 0: {
        return (
          <BasicDetails
            offering={offering}
            errors={errors}
            handleSetOffering={handleSetOffering}
          />
        );
      }
      case 1: {
        return (
          <Content
            errors={errors}
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      }
      case 2: {
        return (
          <Advanced offering={offering} handleSetOffering={handleSetOffering} />
        );
      }
      default:
        return <></>;
    }
  };

  // useEffect(() => {
  //   if (isNullOrUndefined(savedChangesState)) {
  //     enqueueSnackbar("Your progress will be automatically saved");
  //   } else {
  //     enqueueSnackbar(getSavedStateData(savedChangesState).text, {
  //       variant: getSavedStateData(savedChangesState).variant,
  //     });
  //   }
  // }, [savedChangesState]);

  return (
    !loading && (
      <>
        <AddFlex
          flexDirection="column"
          padding={isMobileView() ? "0 20px" : "0 48px"}
          width={isMobileView() ? "100%" : "75%"}
          style={{
            paddingBottom: "150px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {!isMobileView() && (
            <PrimaryActionButton
              onClick={() => {
                apiCall();
                navigate(-1);
              }}
              style={{ position: "fixed", right: 48, top: 20 }}
            >
              Save And Exit
            </PrimaryActionButton>
          )}
          <AddFlex
            alignItems="center"
            backgroundColor="white"
            style={{
              position: "fixed",
              zIndex: 100,
              width: isMobileView() ? "100%" : "80%",
              paddingBottom: "8px",
            }}
          >
            <Tabs
              // margin={!isMobileView() && "0 0 0 -20px"}
              tabs={tabs}
              icons={tabIcons}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
            {!isMobileView() && (
              <AddFlex
                alignItems="center"
                style={{
                  position: isMobileView() ? "fixed" : "absolute",
                  marginRight: !isMobileView() && "50%",
                  top: isMobileView() && "20px",
                  right: isMobileView() ? "20px" : "48px",
                }}
              >
                {getSavedStateData(savedChangesState).icon}
                <CustomText
                  margin="0 10px"
                  color={getSavedStateData(savedChangesState).color}
                >
                  {getSavedStateData(savedChangesState).text}
                </CustomText>
              </AddFlex>
            )}
          </AddFlex>

          <AddFlex
            margin="80px 0 0 0"
            width="100%"
            style={{ overflow: "scroll" }}
          >
            {getTabData(selectedTabIndex)}
          </AddFlex>
          {isMobileView() && (
            <AddFlex
              backgroundColor="white"
              padding="10px"
              style={{
                position: "fixed",
                bottom: "0px",
                left: "0px",
                width: "100vw",
                boxShadow: "0 0 2px 4px rgba(0,0,0,0.05)",
              }}
            >
              <PrimaryActionButton
                onClick={() => {
                  apiCall();
                  navigate(-1);
                }}
                style={{ margin: "0 auto", padding: "12px 10px", width: "70%" }}
              >
                Save And Exit
              </PrimaryActionButton>
            </AddFlex>
          )}
        </AddFlex>
      </>
    )
  );
}

export default CreateOfferingMain;
