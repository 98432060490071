import React from "react";
import { AddFlex, CustomText } from "./reusableStyles";

function Tabs({
  margin,
  tabs,
  icons,
  tabMargin,
  selectedTabIndex,
  setSelectedTabIndex,
}) {
  return (
    <AddFlex margin={margin} style={{ overflow: "scroll" }}>
      <AddFlex alignItems="center">
        {tabs.map((tab, index) => (
          <>
            <AddFlex
              padding={"10px 0px"}
              margin="0 0px 0 0"
              alignItems="center"
              gap="5px"
              onClick={() => setSelectedTabIndex(index, tab)}
              style={{ flexShrink: 0 }}
            >
              <CustomText
                fontWeight="500"
                color={index === selectedTabIndex && "#2E5DCD"}
              >
                {tab.name}
              </CustomText>
              {icons && icons[index.toString()]?.map((icon) => icon)}
              {index === selectedTabIndex && (
                <div
                  style={{
                    position: "absolute",
                    left: "30%",
                    bottom: 0,
                    height: "2px",
                    width: "40%",
                    borderRadius: "8px",
                    backgroundColor: "#2E5DCD",
                  }}
                ></div>
              )}
            </AddFlex>
            {index !== tabs.length - 1 && (
              <div
                style={{
                  margin: tabMargin ? tabMargin : "0 15px",
                  top: "25%",
                  height: "50%",
                  width: "1px",
                  backgroundColor: "#ececec",
                }}
              ></div>
            )}
          </>
        ))}
      </AddFlex>
    </AddFlex>
  );
}

export default Tabs;
