import { Backdrop } from "@mui/material";
import React, { useState } from "react";
import GalleryComponent from "./CreateOffering/GalleryComponent";
import { AddFlex, InputLabel, PrimaryActionButton } from "./reusableStyles";
import { isNullOrUndefined } from "./utils";
import GalleryComponentWrapper from "./GalleryComponentWrapper";

function OpenGalleryButton({
  component,
  buttonText,
  label,
  files,
  selectedComponent,
  handleGetFiles,
  isMultiple,
}) {
  const [openGallery, setOpenGallery] = useState(false);
  const handleOpenGallery = () => {
    setOpenGallery(true);
  };
  return (
    <AddFlex>
      {isNullOrUndefined(component) ? (
        <AddFlex
          flexDirection="column"
          onClick={() => {
            handleOpenGallery();
          }}
          gap="8px"
        >
          <InputLabel style={{ margin: 0 }}>{label}</InputLabel>
          {selectedComponent}
          <PrimaryActionButton style={{ width: "max-content" }}>
            {buttonText}
          </PrimaryActionButton>
        </AddFlex>
      ) : (
        React.cloneElement(component, {
          onClick: () => {
            handleOpenGallery();
          },
        })
      )}
      <GalleryComponentWrapper
        openGallery={openGallery}
        setOpenGallery={setOpenGallery}
        files={files}
        handleGetFiles={handleGetFiles}
        isMultiple={isMultiple}
      />
    </AddFlex>
  );
}

export default OpenGalleryButton;
