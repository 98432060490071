import React from "react";
import {
  AddFlex,
  Arrow,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../../reusableStyles";
import { headerTextColor } from "../../constants";
import {
  FeaturesKeys,
  getDefault,
  getDefaultCourse,
  getDefaultOffering,
  getDefaultOnetoOneOffering,
  getDefaultWebinar,
  getSubscriptionOffering,
  getTravelDefaultOffering,
} from "../../utils";
import { useDispatch } from "react-redux";
import { UpdateFeature } from "../../Api/ApiEndPoints";
import { userFeatureApi } from "../../Store/UserFeaturesSlice";
import { NEW_PAGE_ROUTE } from "../../OnboardingPage/routeNames";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { setPagesTabIndexAction } from "../../Store/appTempSlice";

function CreateOfferingDialogCard({ offering }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );

  const handleCreateNewOffering = () => {
    const defaultOffering = getDefaultOffering(
      UserAdditionalDetails,
      offering.pageType
    );
    dispatch(
      userFeatureApi({
        payload: {
          action: "addnew",
          itemData: defaultOffering,
          featureId: FeaturesKeys[offering.pageType.toLowerCase()].id,
        },
        endpoint: UpdateFeature,
      })
    ).then(() => {
      dispatch(setPagesTabIndexAction(1));
      const queryParams = new URLSearchParams({
        pageType: offering.pageType,
      }).toString();
      navigate(
        NEW_PAGE_ROUTE.replace(":offeringId", defaultOffering.id) +
          `?${queryParams}`
      );
    });
  };
  return (
    <>
      <AddFlex alignItems="center" flexDirection="column">
        <CustomText
          textAlign="center"
          fontSize="18px"
          fontWeight="500"
          color="black"
        >
          {offering.name}
        </CustomText>
        <CustomText
          textAlign="center"
          fontSize="12px"
          fontWeight="400"
          color="black"
          margin="12px 0 0 0"
        >
          {offering.description}
        </CustomText>
      </AddFlex>
      <AddFlex flexDirection="column" gap="5px">
        <CustomText
          margin="0 0 11px 0"
          color={headerTextColor}
          fontWeight="500"
        >
          Features
        </CustomText>
        {offering.features.map((feature, index) => (
          <AddFlex>
            <CustomText
              fontSize="14px"
              color="black"
              style={{ flexShrink: 0 }}
              margin="0 5px 0 0"
              fontWeight="300"
            >
              {index + 1}.{" "}
            </CustomText>
            <CustomText
              style={{ width: "100%" }}
              fontSize="14px"
              fontWeight="300"
              color="black"
            >
              {feature}
            </CustomText>
          </AddFlex>
        ))}
      </AddFlex>
      <Gap />
      <SecondaryActionButton
        onClick={handleCreateNewOffering}
        style={{ margin: "0 auto", borderRadius: "24px" }}
      >
        Create Offering <Arrow>→</Arrow>
      </SecondaryActionButton>
    </>
  );
}

export default CreateOfferingDialogCard;
