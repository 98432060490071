import React, { useEffect } from "react";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import GrowezyLogo from "../ImageAssets/GROWEZY_LOGO_NEW.png";
import {
  FeaturesKeys,
  getApiCallParams,
  getDefaultOffering,
  isArrayNullOrUndefined,
} from "../utils";
import {
  Add,
  ArrowRightAlt,
  ChevronRight,
  CopyAllOutlined,
  Instagram,
} from "@mui/icons-material";
import {
  INTEGRATION_IDS,
  StyledImage,
} from "../Integrations/IntegrationCardItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import instaLogo from "../ImageAssets/insta_logo.png";
import zoomLogo from "../ImageAssets/zoom.png";
import googleCalendar from "../ImageAssets/Google_logo.png";
import { useSelector } from "react-redux";
import { FetchIntegrationsApi, UpdateFeature } from "../Api/ApiEndPoints";
import { usersApiCall } from "../Store/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { primaryActionColor } from "../constants";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { NEW_PAGE_ROUTE } from "../OnboardingPage/routeNames";
import ProfileActions from "./ProfileActions";
import PageActions from "./PageActions";
import FormActions from "./FormActions";
import TestimonialActions from "./TestimonialActions";
import useWindowSize from "../Hooks/useWindowSize";

function QuickActions() {
  const { isMobileView } = useWindowSize();
  const integrations = useSelector(
    (state) => state.user.integrations?.integrations
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getIntegration = (integrationId) => {
    if (isArrayNullOrUndefined(integrations)) {
      return false;
    }
    const integration = integrations.find(
      (integration) => integration.id === integrationId
    );
    return integration;
  };

  const integrationActions = [
    {
      name: "Sync your instagram",
      description:
        "Watch this 2 mins introductory video to get an overview of our platform and a breakdown of key features you need to know.",
      icon: (
        <StyledImage
          src={instaLogo}
          style={{ width: "25px", height: "25px", margin: 0 }}
        />
      ),
      rightIcon:
        getIntegration(INTEGRATION_IDS.INSTAGRAM).isActivated === true ? (
          <CheckCircleIcon sx={{ color: "green", fontSize: "18px" }} />
        ) : (
          <ArrowRightAlt />
        ),
      action: () => {
        if (getIntegration(INTEGRATION_IDS.INSTAGRAM).isActivated) {
          return;
        }
        navigate("/integrations/instagram");
      },
    },
    {
      name: "Sync your google calendar",
      description:
        "Watch this 2 mins introductory video to get an overview of our platform and a breakdown of key features you need to know.",
      icon: (
        <StyledImage
          src={googleCalendar}
          style={{ width: "25px", height: "25px", margin: 0 }}
        />
      ),
      action: () => {
        if (getIntegration(INTEGRATION_IDS.GOOGLE_CALENDAR).isActivated) {
          return;
        }
        navigate("/integrations/googlecalendar");
      },
      rightIcon:
        getIntegration(INTEGRATION_IDS.GOOGLE_CALENDAR).isActivated === true ? (
          <CheckCircleIcon sx={{ color: "green", fontSize: "18px" }} />
        ) : (
          <ArrowRightAlt />
        ),
    },
    {
      name: "Sync your zoom.",
      description:
        "Watch this 2 mins introductory video to get an overview of our platform and a breakdown of key features you need to know.",
      icon: (
        <StyledImage
          src={zoomLogo}
          style={{ width: "25px", height: "25px", margin: 0 }}
        />
      ),
      action: () => {
        if (getIntegration(INTEGRATION_IDS.ZOOM).isActivated) {
          return;
        }
        navigate("/integrations/zoom");
      },
      rightIcon:
        getIntegration(INTEGRATION_IDS.ZOOM).isActivated === true ? (
          <CheckCircleIcon sx={{ color: "green", fontSize: "18px" }} />
        ) : (
          <ArrowRightAlt />
        ),
    },
  ];

  useEffect(() => {
    const fetchIntegrations = async () => {
      dispatch(usersApiCall(getApiCallParams(FetchIntegrationsApi)));
    };
    fetchIntegrations();
  }, []);

  return (
    <AddFlex
      alginItems="flex-start"
      gap={isMobileView() ? "20px" : "20px"}
      wrap={isMobileView() && "wrap-reverse"}
    >
      <AddFlex
        gap="20px"
        flexDirection="column"
        width={isMobileView() ? "100%" : "45%"}
      >
        <ProfileActions />
        <PageActions />
        <FormActions />
        <TestimonialActions />
      </AddFlex>
      {/* {!isMobileView() && <Gap />} */}
      <QuickActionsSection
        sectionWidth={isMobileView() ? "100%" : "45%"}
        sectionTitle={"Quick Actions"}
        actions={integrationActions}
      />
    </AddFlex>
  );
}

export default QuickActions;

export const ActionSectionLeft = ({
  sectionTitle,
  actionOrientation,
  actions,
  sectionDescription,
}) => {
  return (
    <AddFlex
      style={{
        boxShadow: "0 0 5px 1px rgba(0,0,0,0.1)",
        border: "1px solid #D0D5DD",
        height: "max-content",
      }}
      flexDirection="column"
      borderRadius="12px"
      padding="24px"
      gap="24px"
    >
      <AddFlex flexDirection="column">
        <CustomText
          color="black"
          style={{
            fontSize: "17px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "23px",
          }}
        >
          {sectionTitle}
        </CustomText>
        <CustomText
          color="black"
          style={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "23px",
          }}
        >
          {sectionDescription}
        </CustomText>
        <AddFlex
          flexDirection={actionOrientation ? actionOrientation : "row"}
          marginTop="16px"
          gap="10px"
          wrap="wrap"
        >
          {actions.map((action) => (
            <ActionChip action={action} />
          ))}
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
};

const QuickActionsSection = ({
  sectionTitle,
  sectionDescription,
  sectionWidth,
  actions,
}) => {
  return (
    <AddFlex
      style={{
        boxShadow: "0 0 5px 1px rgba(0,0,0,0.1)",
        border: "1px solid #D0D5DD",
        width: sectionWidth ? sectionWidth : "30%",
        height: "max-content",
      }}
      flexDirection="column"
      borderRadius="12px"
      padding="24px"
      gap="24px"
    >
      <AddFlex flexDirection="column">
        <CustomText
          color="black"
          style={{
            fontSize: "17px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "23px",
          }}
        >
          {sectionTitle}
        </CustomText>
        {sectionDescription && (
          <CustomText
            color="black"
            style={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "23px",
            }}
          >
            {sectionDescription}
          </CustomText>
        )}
        <AddFlex gap="24px" flexDirection="column" marginTop="24px">
          {actions.map((action) => (
            <ActionBlock
              titleIcon={action.icon}
              title={action.name}
              description={action.description}
              rightIcon={action.rightIcon}
              onClick={action.action}
            />
          ))}
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
};

const ActionBlock = ({
  title,
  titleIcon,
  width,
  onClick,
  rightIcon,
  description,
}) => {
  return (
    <AddFlex
      padding="12px"
      borderRadius="8px"
      style={{
        border: "1px solid #D0D5DD",
        cursor: "pointer",
        flexShrink: 0,
        width: width ? width : "auto",
      }}
      onClick={onClick}
    >
      <AddFlex gap="6px" flexDirection="column">
        <AddFlex alginItems="center" gap="10px">
          {titleIcon && titleIcon}
          <CustomText
            color="black"
            style={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            {title}
          </CustomText>
          {rightIcon && rightIcon}
        </AddFlex>
        <CustomText
          color="#344054"
          style={{
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {description}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};

const ActionChip = ({ action }) => {
  return (
    <AddFlex
      border={
        action.textOnly
          ? "none"
          : action.colors?.border
          ? action.colors?.border
          : "1px solid #D0D5dd"
      }
      borderRadius="4px"
      backgroundColor={
        action.colors?.backgroundColor && action.colors?.backgroundColor
      }
      alginItems="center"
      padding={action.textOnly ? "0px" : "10px"}
      gap="4px"
      style={{
        fontSize: "14px",
        color: action.colors?.color ? action.colors?.color : "",
        cursor: "pointer",
        width: "max-content",
      }}
      onClick={() => {
        if (action.action) {
          return action.action();
        }
      }}
    >
      {action.name}{" "}
      {action.rightIcon ? (
        action.rightIcon
      ) : (
        <ChevronRight sx={{ fontSize: "16px", color: "black" }} />
      )}
    </AddFlex>
  );
};
