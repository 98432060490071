import { addDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Currency,
  DateFilters,
  FeaturesKeys,
  formatPrice,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../utils";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import ReportTabFilters from "./ReportTabFilters";
import { callNonStoreApi } from "../NonStoreApis";
import {
  FeatureAllSaleReport,
  FeatureSummaryInsights,
  FetchTotalRevenue,
} from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import Loading from "../Loading";
import ReportsSummary from "./ReportsSummary";
import ReportsTabHeader from "./ReportsTabHeader";
import ReportsAllSales from "./ReportsAllSales";
import { useSelector } from "react-redux";
import { setReportsFiltersAction } from "../Store/appTempSlice";
import { useDispatch } from "react-redux";
import { Parser } from "@json2csv/plainjs";
import {
  formatAllSales,
  formatSummaryForGroupByDates,
  formatSummaryForGroupByName,
  formatSummaryForGroupByTickets,
  formatSummaryForGroupByTimeSlots,
} from "./reportHelpers";
import SuccessChip from "../Integrations/SuccessChip";

const GroupBy = ["Name", "Dates", "TimeSlots", "Tickets"];

const data = [
  {
    offeringName: "Event one",
    bookingDate: new Date(),
    date: "23 Mar",
    timeSlot: "10:00 AM",
    customerName: "N. Sai Shashank",
    customerNumber: "+91-9831767621",
    amount: 100000,
    ticket: "Registration Fee",
    offeringStatus: true,
  },
];

function ReportsMain({ featureKey }) {
  const [reportsTypeTabIndex, setReportsTypeTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const reportsFilters = useSelector(
    (state) => state.appTemp.data.reportFilters[featureKey.id]
  );
  const dispatch = useDispatch();
  const [lifetimeStatistics, setLifetimeStatistics] = useState();
  // const {} = useSelector(state => state.userFeatures.)
  const [totalRevenue, setTotalRevenue] = useState();
  const [isFetchingRevenue, setIsFetchingRevenue] = useState(false);
  const [list, setList] = useState({ summary: [], allSales: [] });
  const [tempList, setTempList] = useState({ summary: [], allSales: [] });
  // const []
  const [apiLoading, setApiLoading] = useState(false);

  const handleGetApiEndPoint = () => {
    console.log(featureKey);
    if (featureKey.id === FeaturesKeys.subscription.id) {
      return FeatureAllSaleReport;
    } else {
      return reportsTypeTabIndex === 0
        ? FeatureSummaryInsights
        : FeatureAllSaleReport;
    }
  };

  const getTabKey = () => {
    if (featureKey.id === FeaturesKeys.subscription.id) {
      return "allSales";
    } else {
      return reportsTypeTabIndex === 0 ? "summary" : "allSales";
    }
  };

  const getApiPayload = () => {
    return {
      startDate: getReportsFilters(reportsTypeTabIndex).start,
      endDate: getReportsFilters(reportsTypeTabIndex).end,
      featureId: featureKey.id,
      selectedOfferings: getReportsFilters(
        reportsTypeTabIndex
      ).filterData?.selectedItems?.map((item) => item.id),
      groupBy:
        GroupBy[getReportsFilters(reportsTypeTabIndex).selectedGroupByIndex],
    };
  };

  const handleSearch = () => {
    if (isNullOrUndefined(searchText) || searchText.length === 0)
      return setTempList(list);
    setTempList((prev) => {
      return {
        ...prev,
        [getTabKey()]: list[getTabKey()].filter(
          (item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.customerName?.toLowerCase().includes(searchText.toLowerCase())
        ),
      };
    });
  };

  const getReportsFilters = (reportsTypeTabIndex) => {
    // if (isNullOrUndefined(reportsFilters)) return [];
    return reportsFilters[getTabKey()];
  };

  const formatSummarySales = (sales) => {
    switch (getReportsFilters(reportsTypeTabIndex).selectedGroupByIndex) {
      case 0:
        return formatSummaryForGroupByName(sales);
      case 1:
        return formatSummaryForGroupByDates(sales);
      case 2:
        return formatSummaryForGroupByTimeSlots(sales);
      case 3:
        return formatSummaryForGroupByTickets(sales);
      default:
        return formatAllSales(sales);
    }
  };

  const handleDownloadAllCustomersReport = async () => {
    try {
      const parser = new Parser();

      const csv = parser.parse(
        formatSummarySales(
          reportsTypeTabIndex !== 0 ? tempList.allSales : tempList.summary
        )
      );
      const element = document.createElement("a");
      element.setAttribute("href", `data:text/csv;charset=utf-8,${csv}`);
      element.setAttribute("download", `Report`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const handleFetchTotalRevenue = async () => {
    try {
      setIsFetchingRevenue(true);
      const reponse = await callNonStoreApi(FetchTotalRevenue);
      setTotalRevenue(reponse.data.totalRevenue);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingRevenue(true);
    }
  };

  useEffect(() => {
    handleFetchTotalRevenue();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    const handleCallApi = async () => {
      try {
        setApiLoading(true);
        const response = await callNonStoreApi(
          handleGetApiEndPoint(),
          getApiPayload()
        );
        setList((prev) => {
          return {
            ...prev,
            [getTabKey()]: response.data.list,
          };
        });
        setTempList((prev) => {
          return {
            ...prev,
            [getTabKey()]: response.data.list,
          };
        });
      } catch (error) {
        console.log(error);
        setTempList([]);
        setList([]);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      } finally {
        setApiLoading(false);
      }
    };
    handleCallApi();
  }, [reportsFilters, reportsTypeTabIndex]);

  useEffect(() => {
    //lifetimeStats
    // setTempList([]);
  }, [reportsTypeTabIndex]);

  return (
    <AddFlex
      flexDirection="column"
      style={{
        borderRadius: "4px",
        padding: "0 28px",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0 0 4px 1px rgba(0,0,0,0.1)",
        backgroundColor: "white",
      }}
    >
      {totalRevenue && (
        <div style={{ position: "fixed", top: 30, right: 30 }}>
          <SuccessChip
            successChipText={`Total revenue on Growezy: ${formatPrice(
              totalRevenue,
              Currency.INR
            )}`}
          />
        </div>
      )}
      <ReportsTabHeader
        reportsTypeTabIndex={reportsTypeTabIndex}
        featureKey={featureKey}
        setReportsTypeTabIndex={setReportsTypeTabIndex}
        handleDownloadAllCustomersReport={handleDownloadAllCustomersReport}
      />
      <ReportTabFilters
        startDate={getReportsFilters(reportsTypeTabIndex).start}
        endDate={getReportsFilters(reportsTypeTabIndex).end}
        showGroupBy={
          reportsTypeTabIndex !== 1 &&
          featureKey.id !== FeaturesKeys.subscription.id
        }
        searchText={searchText}
        selectedFilters={getReportsFilters(reportsTypeTabIndex).filterData}
        selectedGroupbyIndex={
          getReportsFilters(reportsTypeTabIndex).selectedGroupByIndex
        }
        setDateRange={(key, value) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: getTabKey(),
              tabName: featureKey.id,
              key: key,
              value: value,
            })
          );
        }}
        setSearchText={setSearchText}
        setSelectedFilters={(filterData) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: getTabKey(),
              tabName: featureKey.id,
              key: "filterData",
              value: filterData,
            })
          );
        }}
        setSelectedGroupbyIndex={(selectedIndex) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: getTabKey(),
              tabName: featureKey.id,
              key: "selectedGroupByIndex",
              value: selectedIndex,
            })
          );
        }}
      />
      {apiLoading ? (
        <AddFlex
          height="100%"
          width="100%"
          justify="center"
          alignItems="center"
        >
          <Loading />
        </AddFlex>
      ) : reportsTypeTabIndex !== 0 ||
        featureKey.id === FeaturesKeys.subscription.id ? (
        <ReportsAllSales list={tempList.allSales} featureKey={featureKey} />
      ) : (
        <ReportsSummary
          list={tempList.summary}
          groupbyIndex={
            getReportsFilters(reportsTypeTabIndex).selectedGroupByIndex
          }
          featureKey={featureKey}
        />
      )}
    </AddFlex>
  );
}

export default ReportsMain;

const DataFileds = ({ title, value }) => {
  return (
    <AddFlex
      gap="8px"
      flexDirection="column"
      alignItems="center"
      justify="center"
    >
      <CustomText color="white">{value}</CustomText>
      <CustomText color="white">{title}</CustomText>
    </AddFlex>
  );
};
