import { ContentHeader } from "../reusableStyles";
import {
  Currency,
  GST,
  isCustomerOutofIndia,
  isNullOrUndefined,
} from "../utils";
import BillingLine from "./BillingLine";

function EventBillDetails({
  isPayementConfirmedPage,
  paymentMode,
  payoutAmount,
  paymentBreakdown,
  paymentGateway,
}) {
  const shouldShowValue = (value) => {
    return !isNullOrUndefined(value) && value > 0;
  };

  const isDiscountApplied = () => {
    return (
      !isNullOrUndefined(paymentBreakdown.discount) &&
      Number(paymentBreakdown.discount) > 0
    );
  };

  const shouldShowGST = () => {
    return (
      !isNullOrUndefined(paymentBreakdown.isGstEnabled) &&
      paymentBreakdown.isGstEnabled &&
      !isNullOrUndefined(paymentBreakdown.isGSTExclusivePrice) &&
      paymentBreakdown.isGSTExclusivePrice
    );
  };

  return (
    <>
      <div>
        {!shouldShowValue(payoutAmount) && (
          <ContentHeader
            fontWeight="400"
            fontSize="14px"
            color={"black"}
            margin="0 0 20px 0"
            style={{ fontFamily: "Outfit" }}
          >
            BILL DETAILS
          </ContentHeader>
        )}
        <BillingLine
          title={"Cart Value"}
          makeBold={false}
          currency={paymentBreakdown.currency}
          value={paymentBreakdown.cartValue}
        />
        {isDiscountApplied() && (
          <BillingLine
            title={"Discount"}
            makeBold={true}
            color={"green"}
            discount={true}
            value={paymentBreakdown.discount}
            currency={paymentBreakdown.currency}
          />
        )}
        {shouldShowGST() && (
          <BillingLine
            title={`GST(${GST * 100}%)`}
            currency={paymentBreakdown.currency}
            value={paymentBreakdown.gst}
          />
        )}
        {shouldShowValue(paymentBreakdown.convenience) && (
          <BillingLine
            title={"Convenience"}
            makeBold={false}
            currency={paymentBreakdown.currency}
            value={paymentBreakdown.convenience}
          />
        )}
        {/* {console.log(paymentBreakdown)} */}
        {shouldShowValue(paymentBreakdown.platformFee) && (
          <BillingLine
            title={"Commission"}
            makeBold={false}
            currency={paymentBreakdown.currency}
            value={paymentBreakdown.platformFee}
          />
        )}
        <BillingLine
          title={"Total"}
          makeBold={!isCustomerOutofIndia(paymentBreakdown.currency)}
          value={paymentBreakdown.totalAmount}
          currency={paymentBreakdown.currency}
          marginTop={"15px"}
        />
        {isCustomerOutofIndia(paymentBreakdown.currency) &&
          (paymentGateway === "phonepe" || !isPayementConfirmedPage) && (
            <>
              <BillingLine
                title={"Forex Conversion USD to INR"}
                makeBold={false}
                value={paymentBreakdown.forexConversionRate}
                marginTop={"5px"}
                currency={Currency.INR}
              />
              <BillingLine
                title={"Grand Total"}
                makeBold={true}
                currency={Currency.INR}
                value={
                  (paymentBreakdown.forexConversionRate *
                    paymentBreakdown.totalAmount) /
                  100
                }
                marginTop={"5px"}
              />
            </>
          )}
      </div>
      {isPayementConfirmedPage && paymentMode && (
        <BillingLine
          title={"Payment mode"}
          makeBold={false}
          value={paymentMode.toUpperCase()}
          valueIsText={true}
          // currency={currency}
        />
      )}
      {shouldShowValue(payoutAmount) && (
        <BillingLine
          title={"You recieve"}
          makeBold={true}
          color={"green"}
          value={payoutAmount}
          currency={paymentBreakdown.currency}
          marginTop={"5px"}
        />
      )}
    </>
  );
}

export default EventBillDetails;
